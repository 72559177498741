<script setup lang="ts">
import { computed, ref } from 'vue'
import type { Career } from '../schemas/career.ts'
import { marked } from 'marked'
import { convertDateToMonthYear } from '../helpers/dates'
import ProjectComponent from '../components/ProjectComponent.vue'

type Props = { job: Career }
const props = defineProps<Props>()

const projectId = ref<number>(-1)

const componentKey = ref(0)
function forceRenderer() {
  componentKey.value += 1
}

const sortedProjects = computed(() => {
  const projects = props.job.projects
  return projects?.sort(
    (a, b) => Number(b.year?.substring(b.year?.length - 4)) - Number(a.year?.substring(a.year?.length - 4)),
  )
})

function closeProject(id: number) {
  projectId.value = id
  return projectId
}
</script>

<template>
  <div class="flex flex-col xl:flex-row">
    <div class="flex flex-col xl:flex-row w-full xl:w-1/2 xl:mr-6 mb-4" data-aos="fade-right">
      <div class="flex flex-row justify-between xl:flex-col">
        <div class="w-24">{{ convertDateToMonthYear(job.end_date) }}</div>
        <div class="w-24">{{ convertDateToMonthYear(job.start_date) }}</div>
      </div>

      <div class="bg-param border-4 border-param rounded-2xl h-auto w-auto"></div>

      <div class="flex flex-col mx-2 justify-between">
        <div class="my-2 txt-bold text-base md:text-lg lg:text-xl">{{ job.job }}</div>
        <div class="flex justify-between mt-2">
          <div class="txt-regular text-sm md:text-base lg:text-lg">{{ job.type }} - {{ job.firm }}</div>
          <div class="txt-regular text-sm md:text-base lg:text-lg">{{ job.site }}</div>
        </div>
        <div class="my-2 grow txt-light text-sm md:text-base lg:text-lg" v-html="marked.parse(job.details ?? '')"></div>
        <div v-if="job.projects?.length >= 1" class="flex my-2 flex-wrap gap-y-4">
          <div class="txt-bold">Projets:</div>
          <button
            v-for="project in sortedProjects"
            :key="project.id"
            class="bg-param border-param rounded-md mx-2 px-2 shadow shadow-current text-sm md:text-base"
            @click="[(projectId = project.id), forceRenderer()]"
          >
            {{ project.title }}
          </button>
        </div>
      </div>
    </div>

    <div
      v-if="projectId >= 0"
      class="flex flex-col justify-between h-fit w-full xl:w-1/2 px-4 mt-1 pb-4 bg-paramWithOpacity bg-opacity-10"
      data-aos="fade-down"
    >
      <ProjectComponent :id="projectId" :key="componentKey" @close-project="closeProject" />
    </div>
  </div>
</template>
