import { z } from 'zod'

export const skillSchema = z.object({
  id: z.number(),
  title: z.string(),
  competence_level: z.number(),
  appetite_level: z.number(),
  category: z.string(),
  on_cv: z.boolean(),
})

export type Skill = z.infer<typeof skillSchema>
