<script setup lang="ts">
import IconMoon from './icons/IconMoon.vue'
import IconSun from './icons/IconSun.vue'

type Props = { darkMode: boolean }
const props = defineProps<Props>()
</script>

<template>
  <div class="h-16 flex items-center justify-between px-2 w-full max-w-[1600px] print:hidden">
    <div class="txt-light text-xs md:text-lg lg:text-xl xl:text-3xl">
      <h1 class="px-2 py-1 rounded-xl bg-highlight">Développeuse Python</h1>
    </div>

    <div
      class="justify-self-end scale-75 sm:scale-100 h-6 border-param border-2 flex items-center justify-between rounded-full mr-4"
    >
      <button class="w-5 flex items-center" @click="$emit('change-dark-mode', false)">
        <IconSun :class="!props.darkMode ? 'hidden' : 'flex'" class="" />
      </button>
      <button class="w-5 flex items-center" @click="$emit('change-dark-mode', true)">
        <IconMoon :class="props.darkMode ? 'hidden' : 'flex'" />
      </button>
    </div>
  </div>
</template>
