import { z } from 'zod'
import { skillSchema } from '../schemas/skills'
import { projectImageSchema } from '../schemas/projectImage'

export const projectSchema = z.object({
  id: z.number(),
  title: z.string(),
  description: z.string(),
  github_link: z.string().nullable(),
  app_link: z.string().nullable(),
  category: z.string().nullable(),
  year: z.string().nullable(),
  experience: z.number().nullable(),
  skills: z.array(skillSchema).nullable(),
  images: z.array(projectImageSchema).nullable(),
})

export type Project = z.infer<typeof projectSchema>
