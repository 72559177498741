<script setup lang="ts"></script>
<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_18_1325)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H24V24H0V0Z" fill="#FCD207" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 18.761C4.74186 17.6345 7.98345 15.4982 8.34181 11.4215C8.46526 9.90974 9.11797 8.61278 10.8305 7.74608C13.2233 6.81722 14.3531 7.14505 15.3241 7.63086C16.3918 8.57245 16.7005 9.93454 15.9001 11.9112C15.5324 13.0665 12.5473 14.4906 11.1762 14.8896C6.65342 16.9162 4.31488 19.8473 4.71243 23.9112H0V18.761Z"
        fill="black"
      />
      <path
        d="M19.2011 6.82429C20.4514 6.82429 21.4651 5.83386 21.4651 4.6121C21.4651 3.39034 20.4514 2.3999 19.2011 2.3999C17.9507 2.3999 16.937 3.39034 16.937 4.6121C16.937 5.83386 17.9507 6.82429 19.2011 6.82429Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_18_1325">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
