<script setup lang="ts">
import { computed, onMounted } from 'vue'
import type { Skill } from '../schemas/skills'
import { gsap } from 'gsap'

type Props = { skill: Skill }
const props = defineProps<Props>()

const barColor = computed(() => {
  if (props.skill.category == 'Back-end') {
    return 'back'
  } else if (props.skill.category == 'Front-end') {
    return 'front'
  } else {
    return 'other'
  }
})

const wiw = window.innerWidth

function factor() {
  if (wiw >= 680) {
    return 0.6
  } else if (wiw >= 400) {
    return 0.5
  }
  return 0.4
}

onMounted(async () => {
  const f = factor()
  gsap.to('#bar', { duration: 0, width: 0 })
  gsap.to('#bar', { duration: 1, width: wiw * f })
})
</script>

<template>
  <div class="flex flex-col content-start">
    <div class="rounded-lg my-2 flex">
      <div class="w-1/3 text-xs sm:text-sm">{{ skill.title }}</div>
      <div>
        <progress
          id="bar"
          class="rounded-lg h-4 w-2/3"
          :value="skill.competence_level"
          :max="20"
          :class="barColor"
        ></progress>
      </div>
    </div>
  </div>
</template>

<style scoped>
.back::-webkit-progress-value {
  @apply bg-param;
  -webkit-transition: width 5s ease;
  border-radius: 50px;
}
.back::-moz-progress-bar {
  @apply bg-param;
  -moz-transition: width 5s ease;
  border-radius: 50px;
}

.front::-webkit-progress-value {
  @apply bg-highlight;
  transition: all 1s ease;
  border-radius: 50px;
}
.front::-moz-progress-bar {
  @apply bg-highlight;
  border-radius: 50px;
}
.other::-webkit-progress-value {
  @apply bg-gray-400;
  transition: all 1s ease;
  border-radius: 50px;
}
.other::-moz-progress-bar {
  @apply bg-gray-400;
  border-radius: 50px;
}
progress::-webkit-progress-bar {
  background-color: transparent;
  border-radius: 50px;
}

progress {
  background-color: transparent;
  border-radius: 50px;
}
</style>
