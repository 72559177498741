import { type Training, trainingSchema } from '../schemas/training'
import { type ListOf, listOf } from '../schemas/list'

export async function fetchTrainings(): Promise<ListOf<Training>> {
  try {
    const result = await fetch('/api/trainings/')
    const data = await result.json()
    const schema = listOf(trainingSchema)
    const parsed = schema.safeParse(data)
    if (!parsed.success) {
      console.error(`api/trainings/ not matching format ${parsed.error}`)
    }
    return data
  } catch (error) {
    console.error('Error when fetching datas:', error)
  }
}
