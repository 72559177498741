import { type Career, careerSchema } from '../schemas/career'
import { type ListOf, listOf } from '../schemas/list'

export async function fetchCareers(): Promise<ListOf<Career>> {
  try {
    const result = await fetch('api/experiences')
    const data = await result.json()
    const schema = listOf(careerSchema)
    const parsed = schema.safeParse(data)
    if (!parsed.success) {
      console.error(`api/career/ not matching format ${parsed.error}`)
    }
    return data
  } catch (error) {
    console.error('Error when fetching datas:', error)
  }
}
