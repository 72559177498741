<script setup lang="ts"></script>

<template>
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle id="homeLight" r="7" cx="25" cy="9" fill="#FCD207" />
    <rect id="homeDoor" width="15" height="19" x="5" y="34" fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.1707 2.76812e-09C24.2227 -2.49009e-05 23.2839 0.16928 22.4081 0.498252C21.5322 0.827224 20.7363 1.30942 20.066 1.9173L1.88879 18.4004C0.951758 19.2695 0.298058 20.3566 0 21.5416H4.84348V53H19.2319V37.2578H30.1982V53H45.0352V21.5416H50C49.6755 20.1166 48.899 18.8085 47.766 17.7782L30.2755 1.9173C29.6051 1.30942 28.8093 0.827224 27.9334 0.498252C27.0575 0.16928 26.1188 -2.49966e-05 25.1707 2.76812e-09ZM24.9958 16.03C26.7013 16.03 28.3369 16.6443 29.5429 17.7378C30.7489 18.8313 31.4264 20.3145 31.4265 21.861C31.4265 22.6268 31.2602 23.3851 30.937 24.0926C30.6139 24.8001 30.1402 25.4429 29.543 25.9844C28.9459 26.5259 28.237 26.9554 27.4568 27.2485C26.6766 27.5416 25.8403 27.6924 24.9958 27.6924C24.1514 27.6924 23.3151 27.5416 22.5349 27.2485C21.7547 26.9554 21.0458 26.5259 20.4487 25.9844C19.8515 25.4429 19.3778 24.8001 19.0547 24.0926C18.7315 23.3851 18.5651 22.6268 18.5651 21.861C18.5653 20.3145 19.2428 18.8313 20.4488 17.7378C21.6548 16.6443 23.2904 16.03 24.9958 16.03Z"
      fill="currentColor"
    />
  </svg>
</template>
