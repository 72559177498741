<script setup lang="ts">
import { computed, ref } from 'vue'
import IconParam from './icons/IconParam.vue'
import IconMaple from './icons/IconLeaf.vue'
import IconSnow from './icons/IconSnow.vue'
import IconFlower from './icons/IconFlower.vue'
import IconAlp from './icons/IconAlp.vue'

type Props = { darkMode: boolean }
const props = defineProps<Props>()

const increased = ref(false)

const colorsMode = computed(() => {
  return !props.darkMode ? 'bg-dark text-light' : 'bg-light text-dark'
})

function increase() {
  return !increased.value
}
</script>

<template>
  <div class="w-9 md:w-10 sm:mr-2 flex flex-col print:hidden">
    <IconParam class="w-10 scale-75 sm:scale-100 mr-2 mt-2" @click="increased = increase()" />
    <div class="text-sm sm:text-md flex-col mt-2 fixed top-12 right-0">
      <div
        :class="[
          increased
            ? 'transition-all ease-in-out duration-1000 opacity-100 w-42 sm:w-48'
            : 'transition-all ease-in-out duration-1000 w-0 opacity-0',
          colorsMode,
        ]"
        class="flex flex-col px-4 mt-2"
      >
        <div class="txt-bold my-6">Themes:</div>

        <button class="flex" @click="$emit('update_season', 'winter')">
          <span class="mr-2 my-2"><IconSnow class="h-5" /></span>
          <span class="ml-2 my-2 hover:text-param">Pyrénées</span>
        </button>

        <button class="flex" @click="$emit('update_season', 'spring')">
          <span class="mr-2 my-2"><IconFlower class="h-5" /></span>
          <span class="ml-2 my-2 hover:text-param">Pilat</span>
        </button>

        <button class="flex" @click="$emit('update_season', 'summer')">
          <span class="mr-2 my-2"><IconAlp class="w-8" /></span>
          <span class="ml-2 my-2 hover:text-param">Alpes</span>
        </button>

        <button class="flex items-center" @click="$emit('update_season', 'fall')">
          <span class="mr-2 my-2"><IconMaple class="h-5" /></span>
          <span class="ml-2 my-2 hover:text-param">Massif Central</span>
        </button>

        <button class="flex justify-end mt-5 mb-3 mx-2" @click="increased = increase()">
          <span class="mt-2 rounded-xl px-2 py-1 text-center bg-param">Quitter</span>
        </button>
      </div>
    </div>
  </div>
</template>
