import './assets/main.css'
import 'aos/dist/aos.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import * as Sentry from '@sentry/vue'

const app = createApp(App)
try {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [],
  })
} catch (e) {
  console.warn('Sentry initialization failed:', e)
}

app.use(router)

app.mount('#app')
