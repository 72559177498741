<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { RouterView } from 'vue-router'
import LeftMenu from './components/LeftMenu.vue'
import RightMenu from './components/RightMenu.vue'
import TopBarParameters from './components/TopBarParameters.vue'
import IconGithub from './components/icons/IconGithub.vue'
import IconCodingame from './components/icons/IconCodingame.vue'

const darkMode = ref(true)
const paramSeason = ref('')

const colorsMode = computed(() => {
  return darkMode.value ? 'bg-dark text-light' : 'bg-light text-dark'
})

const invertColorsMode = computed(() => {
  return darkMode.value ? 'bg-light text-dark' : 'bg-dark text-light'
})

function changeDarkModeFromParam(mode: boolean) {
  darkMode.value = mode
  localStorage.setItem('darkMode', darkMode.value)
  return darkMode
}

function changeDarkModeFromUser() {
  let mode = localStorage.getItem('darkMode')
  if (mode == 'false') {
    darkMode.value = false
    return darkMode
  }
  darkMode.value = true
  return darkMode
}

function changeSeasonFromParam(choice: string) {
  paramSeason.value = choice
  localStorage.setItem('paramSeason', paramSeason.value)
  changeDarkModeFromUser()
  location.reload()
  return paramSeason
}

function changeSeasonFromUser() {
  let season = localStorage.getItem('paramSeason')
  if (season) {
    paramSeason.value = season
    return paramSeason
  }
}

const defaultSeasonalParams = computed(() => {
  let today = new Date()
  let currentYear = today.getFullYear()
  let fall = new Date(currentYear, 8, 20)
  let winter = new Date(currentYear, 11, 20)
  let spring = new Date(currentYear, 2, 20)
  let summer = new Date(currentYear, 5, 20)

  changeSeasonFromUser()
  changeDarkModeFromUser()
  if ((!paramSeason.value && today > spring && today <= summer) || paramSeason.value == 'spring') {
    return {
      avatar: new URL('./assets/avatarPil.png', import.meta.url),
      season: 'spring',
    }
  } else if ((!paramSeason.value && today > summer && today <= fall) || paramSeason.value == 'summer') {
    return {
      avatar: new URL('./assets/avatarAlp.png', import.meta.url),
      season: 'summer',
    }
  } else if ((!paramSeason.value && today > fall && today <= winter) || paramSeason.value == 'fall') {
    return {
      avatar: new URL('./assets/avatarMC.png', import.meta.url),
      season: 'fall',
    }
  } else {
    return {
      avatar: new URL('./assets/avatarPyr.png', import.meta.url),
      season: 'winter',
    }
  }
})

onMounted(async () => {
  const AOS = await import('aos')
  AOS.init()
  changeSeasonFromUser()
  changeDarkModeFromUser()
})
</script>

<template>
  <div
    id="main"
    class="txt-regular min-h-screen relative print:bg-white"
    :class="[defaultSeasonalParams['season'], colorsMode]"
  >
    <div class="flex justify-between pb-12">
      <LeftMenu
        :darkMode="darkMode"
        :avatar="defaultSeasonalParams['avatar']"
        :season="defaultSeasonalParams['season']"
      />

      <div class="flex flex-col items-center w-full">
        <TopBarParameters :darkMode="darkMode" @change-dark-mode="changeDarkModeFromParam" />
        <RouterView />
      </div>
      <RightMenu :darkMode="darkMode" @update_season="changeSeasonFromParam" />
    </div>

    <footer class="w-full absolute bottom-0 print:hidden" :class="[invertColorsMode]">
      <div class="flex justify-between items-center h-12">
        <div class="flex flex-col items-center justify-center ml-4">
          <div class="text-xs mb-2">fabiennerondi@gmail.com</div>
          <div class="text-xs">2024©fabrodev.com</div>
        </div>

        <div class="flex items-center justify-between 2xl:w-1/12 xl:w-1/12 lg:w-2/12 md:w-1/6 sm:w-1/5 w-1/4 mr-4">
          <a href="https://gitlab.com/ChardonBleu/" target="blank">
            <img alt="gitlab" src="./assets/gitlab.png" class="w-5 sm:w-7" />
          </a>
          <a href="https://www.codingame.com/profile/b3060f0f5aa8c526f2918fb5e59813d14021224" target="blank">
            <IconCodingame class="w-5 sm:w-7" />
          </a>
          <a href="https://www.linkedin.com/in/fabienne-rondi/" target="blank">
            <img alt="gitlab" src="./assets/linkedin.png" class="w-5 sm:w-7" />
          </a>
          <a href="https://github.com/ChardonBleu?tab=repositories" target="blank"><IconGithub class="w-5 sm:w-7" /></a>
        </div>
      </div>
    </footer>
  </div>
</template>
