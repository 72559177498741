import { type Project, projectSchema } from '../schemas/project'

export async function fetchProject(id: number): Promise<Project | undefined> {
  try {
    const result = await fetch('api/projects/' + id.toString() + '/')
    const data = await result.json()
    const parsed = projectSchema.safeParse(data)
    if (!parsed.success) {
      console.error(`api/career/ not matching format ${parsed.error}`)
    }
    return data
  } catch (error) {
    console.error('Error when fetching datas:', error)
  }
}
