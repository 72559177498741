<script setup lang="ts"></script>

<template>
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_29_382)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.28165 1.31665C2.19079 1.31665 1.31274 2.19024 1.31274 3.27558V46.6871C1.31274 47.7725 2.19079 48.6462 3.28165 48.6462H36.7168C37.8077 48.6462 38.6859 47.7725 38.6859 46.6871V8.43094C36.242 8.43094 33.8189 8.41256 31.3542 8.43094V1.31665H3.28165Z"
        stroke="currentColor"
        stroke-width="2.62566"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31.1082 0.0120706C31.3948 0.0150216 31.5387 -0.015632 32.1527 0.0108816C32.8551 0.0412157 33.2882 0.525159 33.6559 0.828294C34.0825 1.18005 38.8832 6.0027 38.8832 6.0027C39.1802 6.32351 39.2602 6.39722 39.5114 6.65359C39.6038 6.74778 39.8867 6.98305 39.9286 7.15883C40.0111 7.5053 39.989 8.20883 39.989 8.20883L40 10.2706L29.2258 0.00734177"
        fill="currentColor"
      />

      <path
        d="M15.831 14.504L6.16889 14.4134C5.62931 14.4084 5.19557 14.8417 5.20011 15.3813L5.23014 18.9495C5.23467 19.4891 5.67577 19.9306 6.21536 19.9357L15.8774 20.0262C16.417 20.0312 16.8507 19.5979 16.8462 19.0583L16.8162 15.4901C16.8116 14.9505 16.3705 14.509 15.831 14.504Z"
        fill="currentColor"
      />
      <path
        d="M11.0148 12.3337C13.0629 12.3337 14.7232 10.7182 14.7232 8.72543C14.7232 6.73265 13.0629 5.11719 11.0148 5.11719C8.9667 5.11719 7.3064 6.73265 7.3064 8.72543C7.3064 10.7182 8.9667 12.3337 11.0148 12.3337Z"
        stroke="currentColor"
        stroke-width="1.64164"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.9188 12.04H9.09641C6.93798 12.04 5.18823 13.6144 5.18823 15.5564C5.18823 17.4985 6.93798 19.0728 9.09641 19.0728H12.9188C15.0772 19.0728 16.827 17.4985 16.827 15.5564C16.827 13.6144 15.0772 12.04 12.9188 12.04Z"
        fill="currentColor"
      />
      <g id="title">
        <path
          d="M29.9241 12.6994L21.9004 12.6657C21.3608 12.6635 20.9315 13.099 20.9417 13.6385L20.9449 13.811C20.955 14.3505 21.4007 14.7897 21.9403 14.792L29.964 14.8256C30.5036 14.8279 30.9328 14.3924 30.9227 13.8529L30.9194 13.6804C30.9093 13.1409 30.4637 12.7017 29.9241 12.6994Z"
          fill="currentColor"
        />
        <path
          d="M22.3865 18.4805C22.9291 18.4805 23.369 18.0577 23.369 17.5362C23.369 17.0146 22.9291 16.5918 22.3865 16.5918C21.8439 16.5918 21.4041 17.0146 21.4041 17.5362C21.4041 18.0577 21.8439 18.4805 22.3865 18.4805Z"
          fill="currentColor"
        />
        <path
          d="M29.6819 16.3081L25.7727 16.2802C25.2331 16.2763 24.8005 16.7106 24.8064 17.2502L24.8083 17.4224C24.8143 17.962 25.2565 18.4025 25.7961 18.4064L29.7053 18.4343C30.2449 18.4382 30.6775 18.0039 30.6716 17.4643L30.6697 17.2921C30.6637 16.7525 30.2215 16.312 29.6819 16.3081Z"
          fill="currentColor"
        />
      </g>

      <path
        d="M27.2926 26.5666L18.9807 26.5329C18.4284 26.5306 17.9894 26.9764 18.0001 27.5286L18.0026 27.6552C18.0133 28.2074 18.4697 28.6569 19.0219 28.6591L27.3339 28.6929C27.8862 28.6951 28.3252 28.2493 28.3145 27.6971L28.312 27.5705C28.3013 27.0183 27.8449 26.5689 27.2926 26.5666Z"
        fill="currentColor"
      />
      <path
        d="M27.2926 35.6448L18.9807 35.611C18.4284 35.6088 17.9894 36.0546 18.0001 36.6067L18.0026 36.7334C18.0133 37.2855 18.4697 37.735 19.0219 37.7372L27.3339 37.771C27.8862 37.7732 28.3252 37.3274 28.3145 36.7752L28.312 36.6486C28.3013 36.0965 27.8449 35.647 27.2926 35.6448Z"
        fill="currentColor"
      />
      <path
        d="M31.436 31.2063L18.9507 31.1701C18.4111 31.1685 17.9855 31.6045 18.0002 32.1439L18.0049 32.3168C18.0195 32.8562 18.4688 33.2947 19.0084 33.2963L31.4938 33.3325C32.0334 33.3341 32.4589 32.8981 32.4443 32.3587L32.4396 32.1858C32.4249 31.6464 31.9756 31.2079 31.436 31.2063Z"
        fill="currentColor"
      />
      <path
        d="M31.436 40.5078L18.9507 40.4716C18.4111 40.47 17.9855 40.906 18.0002 41.4454L18.0049 41.6183C18.0195 42.1577 18.4688 42.5962 19.0084 42.5978L31.4938 42.634C32.0334 42.6356 32.4589 42.1996 32.4443 41.6602L32.4396 41.4873C32.4249 40.9479 31.9756 40.5094 31.436 40.5078Z"
        fill="currentColor"
      />
      <g id="coche2">
        <path
          d="M10.0514 41.327L8.50838 40.0768C8.08914 39.7371 7.49737 39.7725 7.18663 40.156C6.8759 40.5395 6.96386 41.1258 7.38311 41.4655L8.92609 42.7158C9.34534 43.0555 9.93711 43.02 10.2478 42.6365C10.5586 42.253 10.4706 41.6668 10.0514 41.327Z"
          fill="currentColor"
        />
        <path
          d="M12.7858 36.5941L8.86474 41.5148C8.56999 41.8847 8.64704 42.4301 9.03683 42.733C9.42662 43.0358 9.98155 42.9815 10.2763 42.6116L14.1973 37.6909C14.4921 37.321 14.415 36.7756 14.0252 36.4727C13.6354 36.1699 13.0805 36.2242 12.7858 36.5941Z"
          fill="currentColor"
        />
      </g>
      <g id="coche1">
        <path
          d="M10.0514 31.5726L8.50838 30.3224C8.08914 29.9827 7.49737 30.0182 7.18663 30.4016C6.8759 30.7851 6.96386 31.3714 7.38311 31.7111L8.92609 32.9614C9.34534 33.3011 9.93711 33.2656 10.2478 32.8821C10.5586 32.4986 10.4706 31.9124 10.0514 31.5726Z"
          fill="currentColor"
        />
        <path
          d="M12.8077 26.8302L8.88671 31.7509C8.59197 32.1208 8.66901 32.6662 9.0588 32.969C9.44859 33.2719 10.0035 33.2176 10.2983 32.8477L14.2193 27.927C14.514 27.5571 14.437 27.0117 14.0472 26.7088C13.6574 26.406 13.1025 26.4603 12.8077 26.8302Z"
          fill="currentColor"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_29_382">
        <rect width="40" height="49.9591" />
      </clipPath>
    </defs>
  </svg>
</template>
