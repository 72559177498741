import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SkillsView from '../views/SkillsView.vue'
import GraduateView from '../views/GraduateView.vue'
import CareerView from '../views/CareerView.vue'
import CVView from '../views/CVView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
    },
    {
      path: '/skills/',
      name: 'skills',
      component: SkillsView,
    },
    {
      path: '/graduate/',
      name: 'graduate',
      component: GraduateView,
    },
    {
      path: '/career/',
      name: 'career',
      component: CareerView,
    },
    {
      path: '/cv/',
      name: 'cv',
      component: CVView,
    },
  ],
})

export default router
