import { z } from 'zod'
import { projectSchema } from '../schemas/project'

export const careerSchema = z.object({
  id: z.number(),
  job: z.string(),
  type: z.string(),
  firm: z.string(),
  site: z.string(),
  details: z.string().nullable(),
  start_date: z.string().date(),
  end_date: z.string().date().nullable(),
  projects: z.array(projectSchema).nullable(),
})

export type Career = z.infer<typeof careerSchema>
