<script setup lang="ts"></script>

<template>
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.4129 1.46689C15.4129 1.08144 15.1508 0.768982 14.8274 0.768982C14.5041 0.768982 14.2419 1.08144 14.2419 1.46689V28.5331C14.2419 28.9185 14.5041 29.231 14.8274 29.231C15.1508 29.231 15.4129 28.9185 15.4129 28.5331V1.46689Z"
      fill="#86a5d4"
      stroke="#86a5d4"
      stroke-width="1.53801"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.0113 2.53392C10.7398 2.19068 10.3196 2.05612 10.0726 2.23337C9.82568 2.41063 9.84553 2.83257 10.117 3.17582L13.6959 7.7016C13.9674 8.04485 14.3876 8.17941 14.6346 8.00215C14.8815 7.8249 14.8617 7.40295 14.5902 7.05971L11.0113 2.53392Z"
      fill="#86a5d4"
      stroke="#86a5d4"
      stroke-width="1.58892"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.9845 11.3268C21.5353 11.2879 21.1484 11.4941 21.1203 11.7875C21.0923 12.0808 21.4338 12.3502 21.883 12.3892L27.8063 12.9028C28.2555 12.9418 28.6424 12.7356 28.6704 12.4422C28.6985 12.1488 28.357 11.8794 27.9078 11.8405L21.9845 11.3268Z"
      fill="#86a5d4"
      stroke="#86a5d4"
      stroke-width="1.5881"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.66028 17.1511C1.21105 17.1122 0.824137 17.3184 0.796109 17.6118C0.768082 17.9051 1.10955 18.1745 1.55879 18.2135L7.48206 18.7271C7.9313 18.7661 8.31818 18.5598 8.34621 18.2665C8.37424 17.9731 8.0328 17.7037 7.58356 17.6648L1.66028 17.1511Z"
      fill="#86a5d4"
      stroke="#86a5d4"
      stroke-width="1.5881"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27.921 17.0801C28.3703 17.0412 28.7572 17.2474 28.7852 17.5408C28.8132 17.8341 28.4717 18.1035 28.0225 18.1425L22.0992 18.6561C21.65 18.6951 21.2631 18.4888 21.2351 18.1955C21.2071 17.9021 21.5485 17.6327 21.9977 17.5938L27.921 17.0801Z"
      fill="#86a5d4"
      stroke="#86a5d4"
      stroke-width="1.5881"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.4994 11.254C7.94864 11.2151 8.33553 11.4213 8.36356 11.7147C8.39159 12.008 8.05013 12.2774 7.6009 12.3164L1.67762 12.83C1.22838 12.869 0.841472 12.6627 0.813445 12.3694C0.785417 12.076 1.12689 11.8066 1.57612 11.7677L7.4994 11.254Z"
      fill="#86a5d4"
      stroke="#86a5d4"
      stroke-width="1.5881"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.7946 19.3525C21.5977 18.9658 21.2126 18.7565 20.9344 18.8851C20.6562 19.0136 20.5902 19.4313 20.7871 19.818L23.3824 24.9165C23.5792 25.3032 23.9643 25.5124 24.2425 25.3839C24.5207 25.2554 24.5867 24.8377 24.3899 24.451L21.7946 19.3525Z"
      fill="#86a5d4"
      stroke="#86a5d4"
      stroke-width="1.58864"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.7155 10.8527C21.5186 11.2394 21.1335 11.4487 20.8553 11.3201C20.5771 11.1916 20.5111 10.7739 20.708 10.3872L23.3033 5.28872C23.5001 4.90203 23.8852 4.69276 24.1634 4.8213C24.4416 4.94984 24.5076 5.36751 24.3108 5.75419L21.7155 10.8527Z"
      fill="#86a5d4"
      stroke="#86a5d4"
      stroke-width="1.58864"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.29968 24.7708C6.10285 25.1575 5.71775 25.3668 5.43954 25.2382C5.16132 25.1097 5.09536 24.692 5.29219 24.3053L7.8875 19.2068C8.08433 18.8201 8.46943 18.6108 8.74764 18.7394C9.02586 18.8679 9.09182 19.2856 8.89499 19.6723L6.29968 24.7708Z"
      fill="#86a5d4"
      stroke="#86a5d4"
      stroke-width="1.58864"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.86927 10.7808C8.0661 11.1675 8.4512 11.3768 8.72941 11.2482C9.00762 11.1197 9.07359 10.702 8.87676 10.3154L6.28145 5.21681C6.08461 4.83013 5.69951 4.62087 5.4213 4.7494C5.14309 4.87794 5.07712 5.29561 5.27396 5.68229L7.86927 10.7808Z"
      fill="#86a5d4"
      stroke="#86a5d4"
      stroke-width="1.58864"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.8951 22.339C15.6236 21.9957 15.2034 21.8612 14.9564 22.0384C14.7095 22.2157 14.7293 22.6376 15.0008 22.9809L18.5797 27.5067C18.8512 27.8499 19.2714 27.9845 19.5183 27.8072C19.7653 27.63 19.7455 27.208 19.474 26.8648L15.8951 22.339Z"
      fill="#86a5d4"
      stroke="#86a5d4"
      stroke-width="1.58892"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.5312 2.49706C18.8026 2.15382 19.2229 2.01925 19.4698 2.19651C19.7168 2.37376 19.6969 2.79571 19.4255 3.13896L15.8466 7.66473C15.5751 8.00798 15.1549 8.14254 14.9079 7.96529C14.661 7.78803 14.6808 7.36609 14.9522 7.02284L18.5312 2.49706Z"
      fill="#86a5d4"
      stroke="#86a5d4"
      stroke-width="1.58892"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.7392 22.2723C14.0106 21.929 14.4309 21.7945 14.6778 21.9717C14.9248 22.149 14.905 22.5709 14.6335 22.9142L11.0546 27.4399C10.7831 27.7832 10.3629 27.9178 10.1159 27.7405C9.86897 27.5632 9.88882 27.1413 10.1603 26.798L13.7392 22.2723Z"
      fill="#86a5d4"
      stroke="#86a5d4"
      stroke-width="1.58892"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.80406 7.73089C2.45459 7.53668 2.03921 7.59497 1.87629 7.86107C1.71337 8.12718 1.8646 8.50033 2.21408 8.69453L26.7545 22.3316C27.104 22.5258 27.5193 22.4675 27.6823 22.2014C27.8452 21.9353 27.6939 21.5621 27.3445 21.3679L2.80406 7.73089Z"
      fill="#86a5d4"
      stroke="#86a5d4"
      stroke-width="1.53871"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27.3743 8.70465C27.7242 8.5112 27.8763 8.13836 27.714 7.87191C27.5517 7.60546 27.1365 7.54629 26.7866 7.73974L2.21386 21.3239C1.86392 21.5174 1.7118 21.8902 1.87409 22.1566C2.03638 22.4231 2.45163 22.4823 2.80156 22.2888L27.3743 8.70465Z"
      fill="#86a5d4"
      stroke="#86a5d4"
      stroke-width="1.5387"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
