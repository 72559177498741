<template>
  <div class="flex flex-col">
    <div ref="chart"></div>
    <div ref="legend" class="text-regular"></div>
  </div>
  <div ref="tooltip" class="txt-regular"></div>
</template>
<script>
import * as d3 from 'd3'
import tailwindConfig from '../../tailwind.config.js'
import { watch } from 'vue'

export default {
  name: 'BarChart',
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    watch(() => this.data, this.createChart, { deep: true })
    if (this.data.length > 1) {
      this.createChart()
    }
  },

  methods: {
    createChart() {
      let paramColor = tailwindConfig.theme.extend.colors['param']
      let highlightColor = tailwindConfig.theme.extend.colors['highlight']

      const data = this.data
      let totalWidth = 1200
      let totalHeight = 800
      let margin = { top: 50, right: 50, bottom: 70, left: 150 },
        width = totalWidth - margin.left - margin.right,
        height = totalHeight - margin.top - margin.bottom

      const svg = d3
        .select(this.$refs.chart)
        .append('svg')
        .attr('viewBox', [0, 0, totalWidth, totalHeight])
        .attr('width', totalWidth)
        .attr('height', totalHeight)
        .attr('style', 'max-width: 100%; height: auto;')
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`)

      svg
        .append('rect')
        .attr('x', -margin.left)
        .attr('y', -margin.top)
        .attr('height', totalHeight + margin.bottom)
        .attr('width', totalWidth + margin.right)
        .style('fill', highlightColor)
        .style('opacity', 0.1)

      svg
        .append('g')
        .call((g) =>
          g
            .append('text')
            .attr('x', width + margin.right / 2)
            .attr('y', height + margin.bottom / 2)
            .attr('fill', 'currentColor')
            .attr('text-anchor', 'end')
            .text('Je connais bien'),
        )
        .call((g) =>
          g
            .append('text')
            .attr('x', 0)
            .attr('y', height + margin.bottom / 2)
            .attr('fill', 'currentColor')
            .attr('text-anchor', 'start')
            .text('Je connais'),
        )
        .call((g) =>
          g
            .append('text')
            .attr('x', -margin.left + 5)
            .attr('y', height)
            .attr('fill', 'currentColor')
            .attr('text-anchor', 'start')
            .text("J'aime moins"),
        )
        .call((g) =>
          g
            .append('text')
            .attr('x', -margin.left + 5)
            .attr('y', margin.top / 2)
            .attr('fill', 'currentColor')
            .attr('text-anchor', 'start')
            .text("J'aime beaucoup"),
        )

      const x = d3.scaleLinear().domain([2, 20]).nice().range([0, width])
      svg
        .append('g')
        .attr('transform', 'translate(0,' + height + ')')
        .call(d3.axisBottom(x).tickSize(-height).ticks(5).tickFormat(''))
        .attr('stroke', 'currentColor')
        .attr('stroke-opacity', 0.1)

      const y = d3.scaleLinear().domain([2, 20]).nice().range([height, 0])
      svg
        .append('g')
        .call(d3.axisLeft(y).tickSize(-width).ticks(5).tickFormat(''))
        .attr('stroke', 'currentColor')
        .attr('stroke-opacity', 0.1)

      const color = d3.scaleOrdinal(
        data.map((d) => d.category),
        [paramColor, 'currentColor', highlightColor],
      )
      const shape = d3.symbol().type(d3.symbolCircle).size(500)

      const categories = new Set(data.map((d) => d.category))

      const svgLegend = d3
        .select(this.$refs.legend)
        .append('svg')
        .attr('viewBox', [0, 0, width, 150])
        .attr('width', width)
        .attr('height', 150)
        .attr('style', 'max-width: 100%; height: auto;')
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`)

      svgLegend
        .selectAll('dots')
        .data(categories)
        .enter()
        .append('circle')
        .attr('cx', function (d, i) {
          return i * 200 + width / 4
        })
        .attr('cy', 25)
        .attr('r', 12)
        .style('fill', function (d) {
          return color(d)
        })
      svgLegend
        .selectAll('labels')
        .data(categories)
        .enter()
        .append('text')
        .attr('x', function (d, i) {
          return i * 200 + width / 4 + 20
        })
        .attr('y', 30)
        .style('fill', function (d) {
          return color(d)
        })
        .text(function (d) {
          return d
        })

      const tooltip = d3
        .select(this.$refs.tooltip)
        .style('opacity', 0)
        .attr('class', 'tooltip')
        .style('background-color', '#FFFFFF')
        .style('color', '#000000')

      const mouseover = function () {
        tooltip.transition().duration(500).style('opacity', 0.6)
      }

      const mouseleave = function () {
        tooltip.transition().duration(500).style('opacity', 0)
      }

      const mousemove = function (event, d) {
        tooltip
          .style('left', event.pageX + 10 + 'px')
          .style('top', event.pageY + 10 + 'px')
          .html(d.title)
      }

      const t = d3.transition().duration(700).ease(d3.easeLinear)

      svg
        .append('g')
        .selectAll('path')
        .data(data)
        .join('path')
        .attr('transform', (d) => `translate(0,${y(d.appetite_level)})`)
        .on('mouseover', mouseover)
        .on('mousemove', mousemove)
        .on('mouseleave', mouseleave)
        .attr('fill', (d) => color(d.category))
        .attr('d', (d) => shape(d.category))
        .transition(t)
        .attr('transform', (d) => `translate(${x(d.competence_level)},${y(d.appetite_level)})`)
    },
  },
}
</script>

<style>
div.tooltip {
  position: absolute;
  text-align: center;
  padding: 0.5rem;
  border: 1px solid #313639;
  border-radius: 8px;
  font-size: 1.3rem;
  height: 50px;
  width: auto;
}
</style>
