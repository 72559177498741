import { z } from 'zod'

export function listOf<T extends z.ZodTypeAny>(schema: T) {
  return z.object({
    count: z.number(),
    previous: z.string().nullable(),
    next: z.string().nullable(),
    results: z.array(schema),
  })
}

export type ListOf<T> = {
  count: number
  previous?: string | null
  next?: string | null
  results: T[]
}
