<script setup lang="ts">
import { ref } from 'vue'
import type { ProjectImage } from '../schemas/projectImage'

type Props = { images: Array<ProjectImage> | undefined }
defineProps<Props>()

const currentIndex = ref<number>(0)

function colorPoint(index) {
  return index === currentIndex.value ? 'bg-param' : 'bg-gray-400'
}

function goToImage(index) {
  currentIndex.value = index
}
</script>

<template>
  <div v-if="images?.length > 0" class="w-full relative m-auto overflow-hidden">
    <div class="flex justify-center align-middle w-full">
      <img :src="images ? images[currentIndex].image : ''" :alt="'Image ' + currentIndex" class="w-full h-auto" />
    </div>

    <div v-if="images?.length > 1" class="w-full flex justify-center px-4 my-1">
      <div
        v-for="(image, index) in images"
        :key="index"
        @click="goToImage(index)"
        class="w-2.5 h-2.5 rounded-full my-1 cursor-pointer mx-1"
        :class="colorPoint(index)"
      ></div>
    </div>
  </div>
</template>
