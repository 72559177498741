<script setup lang="ts">
import { ref, onMounted } from 'vue'
import type { Project } from '../schemas/project.ts'
import { fetchProject } from '../services/project'
import { marked } from 'marked'
import ImagesSlider from '../components/ImagesSlider.vue'
import IconClose from './icons/IconClose.vue'

type Props = { id: number }
const props = defineProps<Props>()

defineEmits(['close-project'])

const project = ref<Project>()

onMounted(async () => {
  project.value = await fetchProject(props.id)
})
</script>

<template>
  <div class="flex justify-between items-center mb-2">
    <div class="txt-bold w-1/2 text-base md:text-lg lg:text-xl">{{ project?.title }} ({{ project?.year }})</div>
    <div class="text-sm md:text-base lg:text-lg">Projet {{ project?.category.toLowerCase() }}</div>
    <button @click="$emit('close-project')"><IconClose /></button>
  </div>

  <div class="flex justify-between mb-4">
    <div class="flex flex-col mr-3 justify-center">
      <div
        class="mb-4 txt-light text-sm md:text-base lg:text-lg"
        v-html="marked.parse(project?.description ?? '')"
      ></div>
      <div v-if="project?.github_link" class="text-sm md:text-base lg:text-lg">
        Le dépôt
        <a :href="project?.github_link" class="text-highlight underline text-sm md:text-base lg:text-lg" target="blank">
          {{ project?.title }}
        </a>
      </div>
      <div v-if="project?.app_link" class="text-sm md:text-base lg:text-lg">
        L'application
        <a :href="project?.app_link" class="text-highlight underline text-sm md:text-base lg:text-lg" target="blank">
          {{ project?.title }}
        </a>
      </div>
    </div>
    <div
      v-if="project?.images?.length > 0"
      class="flex-col justify-center min-w-60 w-1/2"
      :class="project?.images?.length == 0 ? 'hidden' : 'hidden md:flex'"
    >
      <ImagesSlider :images="project?.images ? project?.images : undefined" />
    </div>
  </div>

  <div class="flex flex-wrap gap-y-4 mt-2 text-sm md:text-base">
    <div v-for="skill in project?.skills" :key="skill.id" class="bg-highlight rounded-lg px-2 mx-1">
      {{ skill?.title }}
    </div>
  </div>
</template>
