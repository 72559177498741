<script setup lang="ts">
import d3SkillsGraph from '../components/D3SkillsGraph.vue'
import SkillsBar from '../components/SkillsBar.vue'
import { fetchSkills } from '../services/skills'
import { onMounted, ref, computed } from 'vue'
import type { Skill } from '../schemas/skills'

const skills = ref<Array<Skill>>()

const newSkills = computed(() => {
  return skills.value
    ? skills.value.map((skill) => ({
        id: skill.id,
        title: skill.title,
        competence_level: skill.competence_level,
        appetite_level: skill.appetite_level,
        category: skill.category,
      }))
    : []
})

onMounted(async () => {
  const data = await fetchSkills()
  skills.value = data.results
})
</script>

<template>
  <div class="flex flex-col items-center w-11/12 ml-2 max-w-[1600px]">
    <div class="txt-regular self-start text-base md:text-xl lg:text-2xl mb-6 lg:my-10 lg:ml-10">
      Compétences techniques (échelle relative)
    </div>
    <div class="flex flex-wrap justify-between w-full mb-6 md:hidden text-sm">
      <div class="flex mw-2 pb-1">
        <div class="bg-param w-5 h-5 rounded-full"></div>
        <div class="mx-2">Back-end</div>
      </div>
      <div class="flex mw-2 pb-1">
        <div class="bg-highlight w-5 h-5 rounded-full"></div>
        <div class="mx-2">Front-end</div>
      </div>
      <div class="flex mw-2 pb-1">
        <div class="bg-gray-400 w-5 h-5 rounded-full"></div>
        <div class="mx-2">Autre</div>
      </div>
    </div>

    <div class="hidden md:flex">
      <d3SkillsGraph :data="newSkills" />
    </div>
    <div v-for="skill in skills" :key="skill.id" class="w-full md:hidden">
      <SkillsBar :skill="skill" />
    </div>
  </div>
</template>
