<script setup lang="ts"></script>

<template>
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_18_65)">
      <circle id="circle" r="6" cx="25" cy="7" stroke="currentColor" stroke-width="1.5" />
      <rect id="square" width="12" height="12" x="0" y="19" stroke="currentColor" stroke-width="1.5" />
      <path
        id="triangle"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36 31L43 19L50 31L36 31Z"
        stroke="currentColor"
        stroke-width="1.5"
      />
      <path
        d="M23.3789 33.7786C26.993 33.7786 29.9228 30.7041 29.9228 26.9115C29.9228 23.1188 26.993 20.0443 23.3789 20.0443C19.7648 20.0443 16.835 23.1188 16.835 26.9115C16.835 30.7041 19.7648 33.7786 23.3789 33.7786Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="0.168778"
      />
      <path
        d="M31.1201 36.81H15.9309V53.0369H31.1201V36.81Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="2.11699"
      />
      <path
        d="M2.26252 35.0679C2.82887 35.0679 3.28799 34.4496 3.28799 33.687C3.28799 32.9244 2.82887 32.3062 2.26252 32.3062C1.69618 32.3062 1.23706 32.9244 1.23706 33.687C1.23706 34.4496 1.69618 35.0679 2.26252 35.0679Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="2.08957"
      />
      <path
        d="M44.4581 35.1859C45.069 35.1859 45.5642 34.6404 45.5642 33.9674C45.5642 33.2945 45.069 32.749 44.4581 32.749C43.8473 32.749 43.3521 33.2945 43.3521 33.9674C43.3521 34.6404 43.8473 35.1859 44.4581 35.1859Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="2.03856"
      />
      <path
        d="M6.6491 45.9055C7.35466 45.9055 7.92663 45.2334 7.92663 44.4043C7.92663 43.5752 7.35466 42.9031 6.6491 42.9031C5.94355 42.9031 5.37158 43.5752 5.37158 44.4043C5.37158 45.2334 5.94355 45.9055 6.6491 45.9055Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="2.43181"
      />
      <path
        d="M40.1062 46.0426C40.7904 46.0426 41.345 45.4544 41.345 44.7289C41.345 44.0034 40.7904 43.4153 40.1062 43.4153C39.4221 43.4153 38.8674 44.0034 38.8674 44.7289C38.8674 45.4544 39.4221 46.0426 40.1062 46.0426Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="2.2401"
      />
      <path
        d="M14.8617 39.5319C15.4934 39.5319 16.0055 38.9328 16.0055 38.1938C16.0055 37.4548 15.4934 36.8557 14.8617 36.8557C14.2299 36.8557 13.7178 37.4548 13.7178 38.1938C13.7178 38.9328 14.2299 39.5319 14.8617 39.5319Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="2.17248"
      />
      <path
        d="M31.9886 39.5303C32.6204 39.5303 33.1325 38.9313 33.1325 38.1923C33.1325 37.4533 32.6204 36.8542 31.9886 36.8542C31.3569 36.8542 30.8447 37.4533 30.8447 38.1923C30.8447 38.9313 31.3569 39.5303 31.9886 39.5303Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="2.17248"
      />
      <path
        d="M3.59173 33.9836L1.47388 34.8324L4.82325 43.9701L6.94111 43.1213L3.59173 33.9836Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="1.87977"
      />
      <path
        d="M43.0489 34.4019L45.24 35.2487L41.7748 44.3653L39.5838 43.5185L43.0489 34.4019Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="1.90936"
      />
      <path
        d="M13.1517 38.0362L6.89136 42.4397L8.61332 44.8072L14.8736 40.4036L13.1517 38.0362Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="2.44263"
      />
      <path
        d="M38.345 44.9013L32.0847 40.4977L33.8067 38.1303L40.067 42.5338L38.345 44.9013Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="2.44263"
      />
    </g>
    <defs>
      <clipPath id="clip0_18_65">
        <rect width="50.528" height="54.0738" />
      </clipPath>
    </defs>
  </svg>
</template>
