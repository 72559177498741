import { type Skill, skillSchema } from '../schemas/skills'
import { type ListOf, listOf } from '../schemas/list'

export async function fetchSkills(): Promise<ListOf<Skill>> {
  try {
    const result = await fetch('/api/skills/')
    const data = await result.json()
    const schema = listOf(skillSchema)
    const parsed = schema.safeParse(data)
    if (!parsed.success) {
      console.error(`api/skills/ not matching format ${parsed.error}`)
    }
    return data
  } catch (error) {
    console.error('Error when fetching datas:', error)
  }
}
