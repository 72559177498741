<script setup lang="ts">
import { gsap } from 'gsap'
import { onMounted, ref, computed } from 'vue'
import { type RouteRecordNameGeneric, RouterLink, useRoute, useRouter } from 'vue-router'
import IconHome from '../components/icons/IconHome.vue'
import IconSkills from '../components/icons/IconSkills.vue'
import IconGraduate from '../components/icons/IconGraduate.vue'
import IconCareer from '../components/icons/IconCareer.vue'
import IconCV from '../components/icons/IconCV.vue'
import IconTurnAvatar from '../components/icons/IconTurnAvatar.vue'

import tailwindConfig from '../../tailwind.config.js'
import * as url from 'node:url'

type Props = { darkMode: boolean; avatar: url; season: string }
const props = defineProps<Props>()

const seasonParam = tailwindConfig.theme.extend.colors['param']
const route = useRoute()
const router = useRouter()

const inSkills = ref(false)
const avatarOn = ref(true)

const borderColors = computed(() => {
  return props.darkMode ? 'border-light' : 'border-dark'
})

function hoverClass() {
  return 'hover:text-param'
}

function currentMenuClass() {
  return 'text-param'
}

function currentIconBorder() {
  return 'border-l-4 border-l-param'
}

function enterHouse() {
  gsap.to('#homeLight', { duration: 0, delay: 0.5, y: 13 })
  gsap.to('#homeDoor', { duration: 0.25, x: 13 })
}

function enterMenu(routeName: RouteRecordNameGeneric, inIcon: boolean) {
  if (routeName == 'home') {
    enterHouse()
  }
  if (routeName == 'skills' && !inIcon) {
    const tl = gsap.timeline()
    tl.to(['#square', '#circle', '#triangle'], { duration: 0.1, delay: 0.25, color: seasonParam })
    tl.to('#triangle', { duration: 0.1, translateX: -37 })
    tl.to(['#square'], { duration: 0.1, svgOrigin: '25 26', rotation: 90 })
    tl.to('#circle', { duration: 0.1, svgOrigin: '25 26', rotation: 90 })
    tl.to('#circle', { duration: 0.1, translateX: -37 })
    tl.to('#square', { duration: 0.1, svgOrigin: '25 26', rotation: 180 })
    tl.to('#triangle', { duration: 0.1, svgOrigin: '25 26', rotation: 90 })
    tl.to('#square', { duration: 0.1, translateX: -37 })
    tl.to('#circle', { duration: 0.1, svgOrigin: '25 26', rotation: 180 })
    tl.to('#triangle', { duration: 0.1, svgOrigin: '25 26', rotation: 180 })

    tl.play()
    return true
  }
  if (routeName == 'graduate') {
    gsap.to('#rose', { duration: 0.1, color: seasonParam })
    gsap.to('#rose', { duration: 2, rotation: 180, transformOrigin: '50% 50%' })
    gsap.to('#rose', { duration: 0, rotation: 0, delay: 2.2 })
  }
  if (routeName == 'career') {
    gsap.to('#job', { duration: 0.1, color: seasonParam })
    gsap.to('#job', { duration: 0.5, translateY: -12, translateX: 7, delay: 0.1 })
    gsap.to('#goal', { duration: 0.5, translateY: 12, translateX: -7, delay: 0.1, opacity: 0 })
  }
  if (routeName == 'cv') {
    gsap.to('#title', { duration: 0.2, color: seasonParam })
    gsap.to('#coche1', { duration: 0.2, delay: 0.1, color: seasonParam })
    gsap.to('#coche2', { duration: 0.2, delay: 0.2, color: seasonParam })
  } else {
    return true
  }
}

function leaveMenu(routeName: RouteRecordNameGeneric, inIcon) {
  if (routeName !== 'home') {
    gsap.to('#homeLight', { duration: 0, y: 0 })
    gsap.to('#homeDoor', { duration: 0.25, delay: 0.5, x: 1 })
  }
  if (routeName !== 'skills' && inIcon) {
    const tlSkillsJuggle = gsap.timeline()
    tlSkillsJuggle.to('#square', { duration: 0.1, translateX: 0 })
    tlSkillsJuggle.to('#circle', { duration: 0.1, svgOrigin: '25 26', rotation: 90 })
    tlSkillsJuggle.to('#triangle', { duration: 0.1, svgOrigin: '25 26', rotation: 90 })
    tlSkillsJuggle.to('#circle', { duration: 0.1, translateX: 0 })
    tlSkillsJuggle.to('#triangle', { duration: 0.1, svgOrigin: '25 26', rotation: 0 })
    tlSkillsJuggle.to('#square', { duration: 0.1, svgOrigin: '25 26', rotation: 90 })
    tlSkillsJuggle.to('#triangle', { duration: 0.1, translateX: 0 })
    tlSkillsJuggle.to('#circle', { duration: 0.1, svgOrigin: '25 26', rotation: 0 })
    tlSkillsJuggle.to('#square', { duration: 0.1, svgOrigin: '25 26', rotation: 0 })
    tlSkillsJuggle.to(['#square', '#circle', '#triangle'], { duration: 0.1, color: 'currentColor' })

    tlSkillsJuggle.play()
    return false
  }
  if (routeName !== 'graduate') {
    gsap.to('#rose', { duration: 0.1, color: 'currentColor' })
  }
  if (routeName !== 'career') {
    gsap.to('#job', { duration: 0.1, color: 'currentColor' })
    gsap.to('#job', { duration: 0.5, translateY: 0, translateX: 0, delay: 0.1 })
    gsap.to('#goal', { duration: 0.5, translateY: 0, translateX: 0, delay: 0.1, opacity: 1 })
  }
  if (routeName !== 'cv') {
    gsap.to('#title', { duration: 0.2, color: 'currentColor' })
    gsap.to('#coche1', { duration: 0.2, delay: 0.1, color: 'currentColor' })
    gsap.to('#coche2', { duration: 0.2, delay: 0.2, color: 'currentColor' })
  }
}

function rotateAvatar(avatarOn: boolean) {
  const tlAvatarToPhoto = gsap.timeline()
  const tlPhotoToAvatar = gsap.timeline()

  if (avatarOn) {
    tlAvatarToPhoto.to('#eye', { duration: 0.4, x: -6, opacity: 0 }, 'eye')
    tlAvatarToPhoto.to('#avatar', { duration: 0.4, rotateY: 90 }, 'eye')
    tlAvatarToPhoto.to('#avatar', { duration: 0, display: 'none' })
    tlAvatarToPhoto.to('#photo', { duration: 0, display: 'block' })
    tlAvatarToPhoto.to('#photo', { duration: 0.4, rotateY: 0 })

    tlAvatarToPhoto.play()
    return false
  } else {
    tlPhotoToAvatar.to('#photo', { duration: 0.4, rotateY: 90 })
    tlPhotoToAvatar.to('#avatar', { duration: 0, display: 'block' })
    tlPhotoToAvatar.to('#photo', { duration: 0, display: 'none' })
    tlPhotoToAvatar.to('#avatar', { duration: 0.4, rotateY: 0 }, 'eye')
    tlAvatarToPhoto.to('#eye', { duration: 0.4, delay: 0.4, x: 0, opacity: 1 }, 'eye')

    tlPhotoToAvatar.play()
    return true
  }
}

function wink() {
  gsap.to('#eye', { duration: 0.2, y: 3.5, x: 0, height: 1, width: 7.5 })
  gsap.to('#eye', { duration: 0.2, delay: 0.2, y: 0, x: 0, height: '5.8pt', width: '2.6pt' })
}

onMounted(async () => {
  gsap.to('#photo', { duration: 0, rotateY: 90 })
  await router.isReady()
  if (route.name == 'home') {
    enterHouse()
  }
})
</script>

<template>
  <div
    class="w-transition ease-in-out duration-300 lg:w-52 w-16 border-r border-opacity-25 dynamic-height print:hidden"
    :class="[borderColors]"
  >
    <div class="txt-light text-xs lg:text-xl h-10 mt-5 mb-2 text-center">Fabienne RONDI</div>

    <div class="flex flex-col">
      <div class="flex flex-col transition-all ease-in-out duration-500 scale-0 lg:scale-100" @mouseenter="wink()">
        <div class="relative">
          <div class="absolute top-0 left-0 transition-all ease-in-out duration-300 delay-200 z-0 lg:z-10">
            <svg width="190" height="190" viewBox="-95 -95 190 190" xmlns="http://www.w3.org/2000/svg">
              <rect id="eye" width="3.5" height="7.5" x="13" y="-27" rx="3" ry="3" fill="black"></rect>
            </svg>
          </div>
          <img alt="avatar" id="avatar" class="" :src="avatar.pathname" />
          <img alt="photo" id="photo" class="hidden" src="../assets/photo.png" />
          <div class="mt-2 mr-2 absolute top-0 right-0 z-20" @click="avatarOn = rotateAvatar(avatarOn)">
            <IconTurnAvatar />
          </div>
        </div>
      </div>

      <div class="w-full transition ease-in-out duration-500 scale-75 lg:scale-100 mt-8 flex-grow mr-2">
        <div
          class="flex items-center"
          @click="[enterMenu(route?.name, false), (inSkills = leaveMenu(route.name, inSkills))]"
        >
          <RouterLink to="/">
            <IconHome class="mr-6 pl-4" :class="[route.name === 'home' ? currentIconBorder() : null]" />
          </RouterLink>
          <RouterLink to="/">
            <p
              class="scale-0 lg:scale-100 transition duration-300 ease-in-out transform"
              :class="[hoverClass(), route.name === 'home' ? currentMenuClass() : null]"
            >
              Accueil
            </p>
          </RouterLink>
        </div>
        <div
          class="my-12 flex items-center"
          @click="[(inSkills = enterMenu(route.name, inSkills)), leaveMenu(route.name, inSkills)]"
        >
          <RouterLink to="/skills">
            <IconSkills class="mr-6 pl-4" :class="[route.name === 'skills' ? currentIconBorder() : null]" />
          </RouterLink>
          <RouterLink to="/skills">
            <p
              class="scale-0 lg:scale-100 transition ease-in-out transform"
              :class="[hoverClass(), route.name === 'skills' ? currentMenuClass() : null]"
            >
              Compétences
            </p>
          </RouterLink>
        </div>
        <div
          class="my-12 flex items-center"
          @click="[enterMenu(route.name, false), (inSkills = leaveMenu(route.name, inSkills))]"
        >
          <RouterLink to="/graduate">
            <IconGraduate class="mr-6 pl-4" :class="[route.name === 'graduate' ? currentIconBorder() : null]" />
          </RouterLink>
          <RouterLink to="/graduate">
            <p
              class="scale-0 lg:scale-100 transition ease-in-out transform"
              :class="[hoverClass(), route.name === 'graduate' ? currentMenuClass() : null]"
            >
              Diplômes
            </p>
          </RouterLink>
        </div>
        <div
          class="my-12 flex items-center"
          @click="[enterMenu(route.name, false), (inSkills = leaveMenu(route.name, inSkills))]"
        >
          <RouterLink to="/career">
            <IconCareer class="mr-6 w-14 pl-4" :class="[route.name === 'career' ? currentIconBorder() : null]" />
          </RouterLink>
          <RouterLink to="/career">
            <p
              class="scale-0 lg:scale-100 transition ease-in-out transform"
              :class="[hoverClass(), route.name === 'career' ? currentMenuClass() : null]"
            >
              Expériences
            </p>
          </RouterLink>
        </div>
        <div
          class="flex items-center"
          @click="[enterMenu(route.name, false), (inSkills = leaveMenu(route.name, inSkills))]"
        >
          <RouterLink to="/cv">
            <IconCV class="mr-6 w-14 pl-4" :class="[route.name === 'cv' ? currentIconBorder() : null]" />
          </RouterLink>
          <RouterLink to="/cv">
            <p
              class="scale-0 lg:scale-100 transition ease-in-out transform"
              :class="[hoverClass(), route.name === 'cv' ? currentMenuClass() : null]"
            >
              CV
            </p>
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.dynamic-height {
  min-height: 96vh;
}
</style>
