/** @type {import('tailwindcss').Config} */

export default {
  content: ['./*.html', './src/**/*.{vue,js,ts}'],
  theme: {
    extend: {
      colors: {
        light: ({ opacityValue }) => {
          if (opacityValue !== undefined) {
            return `rgba(var(--light), ${opacityValue})`
          }
          return `rgb(var(--light)`
        },
        lines: ({ opacityValue }) => {
          if (opacityValue !== undefined) {
            return `rgba(var(--lines), ${opacityValue})`
          }
          return `rgb(var(--lines)`
        },
        dark: ({ opacityValue }) => {
          if (opacityValue !== undefined) {
            return `rgba(var(--dark), ${opacityValue})`
          }
          return `rgb(var(--dark)`
        },
        paramWithOpacity: ({ opacityValue }) => {
          if (opacityValue !== undefined) {
            return `rgba(var(--paramWithOpacity), ${opacityValue})`
          }
          return `rgb(var(--paramWithOpacity)`
        },
        highlight: 'var(--highlight)',
        param: 'var(--param)',
      },
    },
    plugins: [],
  },
}
