<script setup lang="ts">
import { gsap } from 'gsap'
import { onMounted } from 'vue'

function homePhoto() {
  try {
    let classMainList = document.getElementById('main').classList
    if (classMainList?.value.includes('fall')) {
      return new URL('../assets/fallMC.jpg', import.meta.url).href
    } else if (classMainList?.value.includes('winter')) {
      return new URL('../assets/winterPyr.jpg', import.meta.url).href
    } else if (classMainList?.value.includes('spring')) {
      return new URL('../assets/springPilat.jpg', import.meta.url).href
    } else if (classMainList?.value.includes('summer')) {
      return new URL('../assets/summerAlp.jpg', import.meta.url).href
    }
  } catch (error) {
    console.error(error)
    return new URL('', import.meta.url).href
  }
}

onMounted(async () => {
  gsap.to('#pano', { duration: 0, scale: 0 })
  gsap.to('#pano', { duration: 0.8, scale: 1 })
  homePhoto()
})
</script>

<template>
  <div class="flex flex-col items-center mb-4 w-11/12 max-w-[2000px]">
    <div id="pano" class="lg:mt-8">
      <img :src="homePhoto()" alt="Photo accueil" />
    </div>

    <div class="txt-light text-xs lg:text-base xl:text-xl 2xl:text-2xl mt-4 lg:mt-8 px-2">
      <p>Professeur de physique chimie dans une première vie.</p>
      <p>Technicienne de maintenance sur remontées mécaniques dans une deuxième vie.</p>

      <p>
        A 47 ans j’ai eu envie d’approfondir une de mes passions de toujours, laissée de côté par intermittence : coder.
        Pour cette nouvelle plongée dans les languages de programmation j’ai choisi
        <span class="txt-bold text-highlight">Python</span>
        . J’ai découvert avec émerveillement la programmation orientée objet, j’ai approfondi mes connaissances en HTML
        et CSS, et j’ai fait entre autres connaissance avec
        <span class="txt-bold text-highlight">Django</span>
        . Un véritable coup de coeur ! Embauchée dès la fin de ma formation chez EcoCO2 j’y ai grandi au sein d’une
        équipe formidable. J’y ai découvert
        <span class="txt-bold text-highlight">Vue.JS</span>
        , un deuxième coup de coeur.
      </p>

      <div class="mt-4">
        Aujourd’hui j’aspire à continuer à gravir les pentes de l’Everest Python, de l’Aconcagua Django et du
        Kilimanjaro Vue.JS, et à découvrir d'autres beaux outils.
      </div>
    </div>
  </div>
</template>
