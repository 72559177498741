<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { fetchTrainings } from '../services/training'
import type { Training } from '../schemas/training'
import GraduateCardRight from '../components/GraduateCardRight.vue'
import GraduateCardLeft from '../components/GraduateCardLeft.vue'

const trainings = ref<Array<Training>>()

onMounted(async () => {
  const data = await fetchTrainings()
  trainings.value = data.results
})
</script>

<template>
  <div class="flex flex-col items-center w-11/12 ml-10 max-w-[1600px]">
    <div class="txt-regular self-start text-base md:text-xl lg:text-2xl mb-4 lg:my-10 lg:ml-10">
      Diplômes et certifications
    </div>

    <div v-for="training in trainings" :key="training.id" class="w-full">
      <div v-if="training.id % 2 == 0">
        <GraduateCardLeft :training="training" />
      </div>
      <div v-else>
        <GraduateCardRight :training="training" />
      </div>
    </div>
  </div>
</template>
