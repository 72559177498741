<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { fetchCareers } from '../services/career'
import type { Career } from '../schemas/career'
import Job from '../components/JobComponent.vue'

const careers = ref<Array<Career>>()

onMounted(async () => {
  const data = await fetchCareers()
  careers.value = data.results
})
</script>

<template>
  <div class="flex flex-col w-11/12 ml-10 max-w-[1600px]">
    <div class="txt-regular self-start text-base md:text-xl lg:text-2xl mb-4 lg:my-10 lg:ml-10">Expériences</div>
    <div v-for="job in careers" :key="job.id" class="my-10"><Job :job="job" /></div>
  </div>
</template>
