<script setup lang="ts"></script>

<template>
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_29_355)">
      <path d="M18.5184 6.5957H5.13599V7.29915H18.5184V6.5957Z" stroke="currentColor" stroke-width="2.18485" />
      <path d="M27.2442 12.1486H5.18921V12.9771H27.2442V12.1486Z" stroke="currentColor" stroke-width="1.91029" />
      <mask
        id="mask0_29_355"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="-5"
        y="-5"
        width="52"
        height="60"
      >
        <path
          d="M-4.91602 -4.91589H46.5946V54.1141H-4.91602V-4.91589ZM26.8673 15.6969C26.831 15.6969 26.8018 15.7261 26.8018 15.7624V50.3206C26.8018 50.3569 26.831 50.3861 26.8673 50.3861H45.5639C45.6002 50.3861 45.6294 50.3569 45.6294 50.3206V15.7624C45.6294 15.7261 45.6002 15.6969 45.5639 15.6969H26.8673Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_29_355)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.04867 0.983154H40.6301C40.6664 0.983154 40.6956 1.01238 40.6956 1.04867V48.1494C40.6956 48.1857 40.6664 48.2149 40.6301 48.2149H1.04867C1.01238 48.2149 0.983154 48.1857 0.983154 48.1494V1.04868C0.983154 1.01238 1.01238 0.983154 1.04867 0.983154Z"
          stroke="currentColor"
          stroke-width="2.18485"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <path
        id="rose"
        d="M30.8307 29.5611C32.8184 32.1052 36.5291 32.5276 39.1187 30.5043C41.7083 28.4811 42.1962 24.7785 40.2085 22.2343C38.2207 19.6901 34.5101 19.2678 31.9205 21.2911C29.3309 23.3143 28.843 27.0169 30.8307 29.5611Z"
        stroke="currentColor"
        stroke-width="3.27728"
      />
      <path
        id="rose"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M38.5206 33.8676C37.9766 34.0718 35.9268 32.1435 35.3468 32.1245C34.7668 32.1054 32.5809 33.8947 32.0532 33.6553C31.5255 33.416 31.4463 30.5993 31.0511 30.1761C30.656 29.7528 27.8498 29.4785 27.6474 28.9358C27.4451 28.3931 29.3828 26.338 29.4041 25.7585C29.4253 25.1789 27.6425 23.0018 27.8841 22.4736C28.1257 21.9455 30.9453 21.8558 31.3704 21.4595C31.7955 21.0631 32.0806 18.2584 32.6246 18.0543C33.1686 17.8501 35.2183 19.7783 35.7983 19.7973C36.3783 19.8164 38.5642 18.0271 39.092 18.2665C39.6197 18.5059 39.6989 21.3225 40.094 21.7458C40.4891 22.169 43.2954 22.4433 43.4977 22.986C43.7001 23.5287 41.7623 25.5838 41.7411 26.1634C41.7199 26.7429 43.5026 28.9201 43.261 29.4482C43.0195 29.9763 40.1999 30.066 39.7748 30.4624C39.3496 30.8587 39.0645 33.6634 38.5206 33.8676Z"
        stroke="currentColor"
        stroke-width="3.27728"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.2739 35.0192V51.2876C30.4077 49.6631 33.2892 47.7409 35.301 47.7576C37.2998 47.7742 40.035 49.718 41.0808 51.354V35.0192H40.8815C40.6959 35.5984 40.3197 36.1063 39.8077 36.4687C39.2957 36.831 38.6749 37.0288 38.0357 37.0332C37.4004 37.0325 36.7817 36.8404 36.2691 36.4849C35.7565 36.1294 35.3765 35.6288 35.1839 35.0554C34.9909 35.6247 34.6129 36.1217 34.104 36.4755C33.5951 36.8294 32.9811 37.0219 32.3498 37.0257C31.7122 37.0212 31.0927 36.8242 30.5815 36.4633C30.0702 36.1024 29.6938 35.5965 29.507 35.0192H29.2739Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="1.08281"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.8327 44.2975C28.8122 44.2975 28.7957 44.3145 28.7957 44.3351V57.8448C28.7957 57.8567 28.8023 57.8654 28.8109 57.8722L29.196 57.3205L35.3661 48.1487L41.5737 57.8534C41.5746 57.8504 41.5773 57.8481 41.5773 57.8448V44.3351C41.5773 44.3145 41.5602 44.2975 41.5397 44.2975H28.8327Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_29_355">
        <rect width="45" height="57.8721" />
      </clipPath>
    </defs>
  </svg>
</template>
