<script setup lang="ts">
import tailwindConfig from '../../../tailwind.config.js'

const turnColor = tailwindConfig.theme.extend.colors['highlight']
</script>

<template>
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_21_1857)">
      <path
        d="M1.04175 4.16664V10.4166M1.04175 10.4166H7.29175M1.04175 10.4166L5.87508 5.87498C6.99461 4.75489 8.37964 3.93665 9.90093 3.49662C11.4222 3.05658 13.0302 3.00909 14.5748 3.35858C16.1194 3.70807 17.5503 4.44314 18.734 5.4952C19.9177 6.54727 20.8155 7.88205 21.3438 9.37498M23.9584 20.8333V14.5833M23.9584 14.5833H17.7084M23.9584 14.5833L19.1251 19.125C18.0056 20.2451 16.6205 21.0633 15.0992 21.5033C13.578 21.9434 11.97 21.9909 10.4254 21.6414C8.88078 21.2919 7.44987 20.5568 6.26619 19.5047C5.08251 18.4527 4.18463 17.1179 3.65633 15.625"
        :stroke="turnColor"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_21_1857">
        <rect width="25" height="25" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
