<script setup lang="ts">
import tailwindConfig from '../../../tailwind.config.js'

const modeColor = tailwindConfig.theme.extend.colors['param']
</script>

<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 1.06475e-09C11.7566 -3.88948e-05 7.68706 1.68558 4.68649 4.68606C1.68592 7.68654 0.000160782 11.7561 4.34033e-09 15.9995C-4.89345e-05 18.1007 0.413755 20.1814 1.21781 22.1226C2.02186 24.0639 3.2004 25.8278 4.68614 27.3136C6.17189 28.7994 7.93574 29.978 9.87697 30.7821C11.8182 31.5862 13.8988 32 16 32C18.1012 32 20.1818 31.5862 22.123 30.7821C24.0642 29.978 25.8281 28.7994 27.3138 27.3136C28.7996 25.8278 29.9781 24.0639 30.7822 22.1227C31.5862 20.1814 32 18.1007 32 15.9995C31.9998 11.7561 30.3141 7.68654 27.3135 4.68606C24.3129 1.68558 20.2433 -4.89188e-05 16 1.06475e-09ZM16.0525 4.04784L16.6966 8.64598L15.4278 8.69471L16.0525 4.04784ZM7.43797 7.55415L11.1783 10.3612L10.2539 11.2368L7.43797 7.55415ZM24.3382 7.57569L21.5213 11.2583L20.5969 10.3827L24.3382 7.57569ZM16.0282 9.21003C17.7741 9.21029 19.4483 9.9287 20.6827 11.2073C21.9171 12.4858 22.6106 14.2198 22.6106 16.0279C22.6106 17.8359 21.9171 19.5699 20.6827 20.8485C19.4483 22.127 17.7741 22.8455 16.0282 22.8457C15.1637 22.8457 14.3076 22.6694 13.5089 22.3268C12.7102 21.9841 11.9844 21.4819 11.3731 20.8488C10.7618 20.2157 10.2769 19.4641 9.94603 18.637C9.61519 17.8098 9.44492 16.9232 9.44493 16.0279C9.44492 15.1325 9.61519 14.246 9.94603 13.4188C10.2769 12.5916 10.7618 11.84 11.3731 11.2069C11.9844 10.5738 12.7102 10.0716 13.5089 9.72898C14.3076 9.38636 15.1637 9.21002 16.0282 9.21003ZM8.85417 15.4152L8.90256 16.6892L4.27472 16.062L8.85417 15.4152ZM22.9841 15.5107L27.5624 16.1574L22.9346 16.7846L22.9841 15.5107ZM21.5766 20.6942L24.3934 24.3768L20.6522 21.5698L21.5766 20.6942ZM10.4615 20.812L11.3858 21.6866L7.64559 24.4946L10.4615 20.812ZM15.3211 23.2119L16.5899 23.2616L15.9448 27.8588L15.3211 23.2119Z"
      :fill="modeColor"
      fill-opacity="1"
    />
    <path
      d="M15.9433 21.1267C18.8383 21.1267 21.1851 18.7897 21.1851 15.9069C21.1851 13.0241 18.8383 10.6871 15.9433 10.6871C13.0483 10.6871 10.7014 13.0241 10.7014 15.9069C10.7014 18.7897 13.0483 21.1267 15.9433 21.1267Z"
      :fill="modeColor"
      fill-opacity="1"
    />
  </svg>
</template>
