import { z } from 'zod'

export const profileSchema = z.object({
  id: z.number(),
  first_name: z.string(),
  last_name: z.string(),
  email: z.string().email({ message: 'Invalid email' }),
  birth_date: z.string().date(),
  github_link: z.string().url({ message: 'Invalid url' }),
  gitlab_link: z.string().url({ message: 'Invalid url' }),
  codingame_link: z.string().url({ message: 'Invalid url' }),
  linkedin_link: z.string().url({ message: 'Invalid url' }),
})

export type Profile = z.infer<typeof profileSchema>
