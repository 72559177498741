<script setup lang="ts">
import tailwindConfig from '../../../tailwind.config.js'

const modeColor = tailwindConfig.theme.extend.colors['param']
</script>

<template>
  <svg width="32" height="32" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 0.505859C11.8907 0.50592 7.94978 2.13835 5.04408 5.04405C2.13838 7.94975 0.50592 11.8907 0.505859 16C0.505919 20.1093 2.13838 24.0502 5.04408 26.9559C7.94978 29.8616 11.8907 31.4941 16 31.4942C20.1093 31.4941 24.0502 29.8616 26.9559 26.9559C29.8617 24.0502 31.4941 20.1093 31.4942 16C31.4941 11.8907 29.8617 7.94975 26.9559 5.04405C24.0502 2.13835 20.1093 0.505919 16 0.505859ZM13.0645 5.19531C11.9195 6.84321 11.2993 8.82339 11.291 10.8574C11.2909 12.1351 11.5324 13.4004 12.0018 14.5809C12.4712 15.7614 13.1593 16.8341 14.0268 17.7376C14.8942 18.6412 15.9241 19.358 17.0576 19.847C18.1911 20.3361 19.4059 20.5878 20.6328 20.5879C22.7661 20.5817 24.8332 19.8152 26.4903 18.416C25.8028 20.5419 24.4511 22.3971 22.6298 23.7144C20.8085 25.0317 18.6116 25.743 16.3555 25.7461C14.956 25.7461 13.5703 25.4734 12.2774 24.9436C10.9845 24.4138 9.80971 23.6372 8.82017 22.6582C7.83064 21.6792 7.04571 20.5169 6.51022 19.2378C5.97472 17.9586 5.69915 16.5876 5.69922 15.2031C5.70333 12.9797 6.41789 10.8145 7.74072 9.01705C9.06355 7.2196 10.9269 5.88194 13.0645 5.19531Z"
      :fill="modeColor"
      fill-opacity="1"
      :stroke="modeColor"
      :stroke-width="0"
    />
  </svg>
</template>
