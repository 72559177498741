import { type Profile, profileSchema } from '../schemas/profile'

export async function fetchProfile(): Promise<Profile> {
  try {
    const result = await fetch('api/profiles/1/')
    const data = await result.json()
    const parsed = profileSchema.safeParse(data)
    if (!parsed.success) {
      console.error(`api/profile/ not matching format ${parsed.error}`)
    }
    return data
  } catch (error) {
    console.error('Error when fetching datas:', error)
  }
}
