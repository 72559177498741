<script setup lang="ts">
import tailwindConfig from '../../../tailwind.config.js'

const fallParam = tailwindConfig.theme.extend.colors['param']
</script>

<template>
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="24" y="10" width="25" height="25" rx="2" :stroke="fallParam" style="stroke-width: 2" />
    <line x1="30" y1="16" x2="44" y2="29" style="stroke-width: 3" :stroke="fallParam" />
    <line x1="44" y1="16" x2="30" y2="29" style="stroke-width: 3" :stroke="fallParam" />
  </svg>
</template>
